import React,{useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Itemvideo from './../atoms/ItemVideo'
import Filter from './../atoms/Filter'
import Modalpopup from './../atoms/Modal/popUp'
import Youtube from 'react-youtube'
import ProductSelection from '../atoms/ProductSelection'

const styles = (theme => ({
    root:{
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.16)',
        borderRadius: '4px',
        padding : '10px 20px 20px 20px',
        minWidth:'30%',
       
    },
    header:{
        borderBottom:'1px solid #959CA9',
        display:'flex',
        justifyContent: 'space-between',
        margin:'2px 0px',
        width:'100%'
    },
    body:{
        overflowY:'auto',
        overflowX:'hidden',
    },
    message:{
        textAlign:'center',
        fontStyle:'italic',
        display:'block'
    },
    title : {
        display:'block',
        float:'left',
        fontSize:'20px',
         margin:'12px 10px 1px 0',
        fontWeight:'900',
        color:'#1B2437 !important'
    }
})
)

function VideoList(data){
    const {classes,videos,message,title,onSelectedFilter,children,orderByAsc,onProductFilter,searchText,productsFirstTime} = data

    function onSelectedFilterAplied (filterBy,descendingorderBy){
        
        if(onSelectedFilter !== null){
            onSelectedFilter(filterBy,descendingorderBy)
        }
    }
  
    const [videoID,setVideoID] = useState('')
    const opts = {
        height: '440px',
        width: '100%',
        playerVars: { 
          autoplay: 1
        }
      };
    function onClickItemVideo(link){
       
        if(link.length > 0){
            setModal(!isOpen)
        }
        setVideoID(getvideoID(link))
        
        console.log(link);
    }

    function onModalClose() {
       
        setModal(false)
    }

    function getvideoID(link) {
        const wsearch = 'watch_popup?v='
        return link.indexOf(wsearch) === -1 ? link.substr(link.indexOf(wsearch) + wsearch.length) 
         : ''
    }   

    const [isOpen,setModal]= useState(false)

    const AppYTbe = ({opts,videoID}) => {
        
         return(
        
        <div className='VideoContainer'>
           <Youtube  videoId={videoID} />
       </div>
    )}

    const onProductFilterApplied = (product) => {
        try {
            if(onProductFilter) {
                onProductFilter(product)
            }
        }
        catch(error) {
            console.log(error)
        }
    }

    return(
        <div>
            <div className={`${classes.root} listVideos`}>
                <div id='video_header' className={`${classes.header} row`}>
                    <div className = {`${classes.title} col-md-2 col-sm-12`}>{title}</div>
                    <ProductSelection onSelected={ onProductFilterApplied }  
                                    productsFirstTime={ productsFirstTime } />
                    <Filter onSelected={ onSelectedFilterAplied }  orderByAsc = {orderByAsc} />
                </div>
                <div className={`${classes.body} bodyList`}>
                {children}
                {
                videos ? videos.length > 0 ?
                    videos.map((val,index) => {  
                        return (
                            <Itemvideo key={index} data = {val} 
                                    product={val.product}
                                    configData={{anchorLinked:false,onclickedPbIcon : onClickItemVideo}}>
                            </Itemvideo>
                        ) 
                })
                :
                (<span className={classes.message}>{message}</span>)
                :
                (<span className={classes.message}>{message}</span>)
                }
            </div>
        </div>
        <Modalpopup isOpen={isOpen} title='Video' onClose={onModalClose}>
                    <AppYTbe opts={opts} videoID={videoID}  />
            </Modalpopup>
        </div>
        
    )
}
VideoList.propTypes = {
    videos: PropTypes.array,
    emptyvideosMessage:PropTypes.string
}

VideoList.defaultProps = {
    message:'',
    title:'PlayList',
    onSelectedFilter:null,
    
}
const VideoListStyles = withStyles(styles)(VideoList)
export default VideoListStyles