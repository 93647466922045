import React,{Component} from 'react'
import Videolist from './../molecules/VideoList'
import InputSearch from './../atoms/InputSearch'
import iconYoutube from './../../assets/youtube-symbol.png'
import servicegetVideos from './../../Services/VideosService'
import Loadergift from './../atoms/Loader'
import Textheader from './../atoms/HeaderText'

import './VideosController.css'

export default class VideoController extends Component{
    
    data = {
        videos: [],
        orderByAsc: true,
        searchText: '',
        isFirtsTime: true,
        filterBy: 'Title',
        indexPage: 1,
        response: null,
        statusLoadingIcon: 'none',
        message: '',
        product: 'PartsBase'
    }
    _getVideos = async (filter) => {
        this.data.message =''
        this.data.videos = null
        this.data.statusLoadingIcon = 'block'
        this.setState(this.data)
        const response = await servicegetVideos(filter)
        this.data.statusLoadingIcon = 'none'
        this.setState(this.data)
      
        this.data.response = response
        if(!this.data.response.ok){
            this.data.message ='Sorry - but we do not have data for this search';
        }
        else{
           if(this.data.response.data === null)
                this.data.message ='Sorry - but we do not have data for this search';
           else
           if(this.data.response.data.items.length === 0)
                this.data.message ='Sorry - but we do not have data for this search';
           
        }
        this.data.videos = this.data.response.data !== null ? this.data.response.data.items : null
        this.setState(this.data)
    }
    async componentDidMount(){
        const {isFirtsTime} = this.data;
        if(isFirtsTime){
            this.data.isFirtsTime = !isFirtsTime;
            this._getVideos(this.data)
        }
    }

    _OnTextChanged = (text) => {
        try {
                this.data.searchText = text
                this._getVideos(this.data)
        }
        catch(error) {
            console.log(error)
        }
    }
   
    _OnFilterChanged = (filterBy,orderByAsc) => {
        try {
            this.data.filterBy = filterBy
            this.data.orderByAsc = orderByAsc
            this._getVideos(this.data)
        } catch (error) {
            console.log(error)
        }
    }

    _OnProductChange = (product) => {
        try {
            if(product === "All products")
            {
                this.data.product = '';
            }
            else {
                this.data.product = product
            }
            this._getVideos(this.data)
        } catch (error) {
            console.log(error);
        }
        
    }

    _textCleaner = (empty) => {
        this.data.searchText = empty
      }
    
    render(){
        const {videos,statusLoadingIcon,message,orderByAsc} = this.data
        return(
            <div className='VideoController'>
                <div className='containerCentral'>
                <div className='MainContainer'>
                <Textheader>
                </Textheader>
                <div className='InputSearch'>
                    <div className='Seccion1'>
                        <div className='FixedpositionSearch'>
                             <InputSearch onResults={this._OnTextChanged} cleanText={this._textCleaner}></InputSearch>
                             <div className='linkVideos'>
                                <a href='https://www.youtube.com/channel/UCEFgdC9x9ljb3UjlEieqN6g' target='_blank' rel='noopener noreferrer'>
                                 Subscribe on YouTube
                                 <img src={iconYoutube} alt='youtube' className='cursorPointer'>
                                </img>
                                </a>
                             </div>
                       </div>
                    </div>
                </div>
                <div className='VideoList'>
                    <Videolist videos={videos} 
                               productsFirstTime={this.data.isFirtsTime}
                               onSelectedFilter={this._OnFilterChanged}
                               onProductFilter={this._OnProductChange} 
                               message={message} 
                               orderByAsc={orderByAsc}>
                         <Loadergift displayVal={statusLoadingIcon} />
                    </Videolist>
                </div>
                </div>
                </div>
            </div>
           
        )
    }
}
